import * as React from "react"
import { graphql } from "gatsby"
import LiveView from "../../views/live-view"

function Live(props) {
  const { live } = props.data
  return <LiveView live={live} />
}

export default Live

export const query = graphql`
  query ($id: String!) {
    live(id: { eq: $id }) {
      name
      nameSlug: gatsbyPath(filePath: "/live/{Live.name}")
      description
      meta {
        id
        date
        cover {
          childImageSharp {
            gatsbyImageData(width: 980, layout: CONSTRAINED)
          }
        }
        src
      }
    }
  }
`
